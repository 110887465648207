<template>
  <div>
    <div class="tw-px-9 tw-py-1 tw-items-center tw-justify-left tw-flex">
      <button
        @click="goBack()"
        class="tw-bg-transparent tw-rounded-full tw-fill-current tw-group tw-text-text-gray-700 tw-text-xl"
      >
        <v-icon
          size="25"
          color="fill"
          class="tw-text-gray-700 tw-fill-current tw-stroke-current group-hover:tw-text-pink-700"
          >mdi-arrow-left</v-icon
        >
        <div
          class="tw-inline-block tw-text-gray-500 tw-font-light tw-text-base tw-ml-2"
        >
          VOLTAR
        </div>
      </button>
    </div>
    <div
      class="tw-p-8 tw-mx-auto tw-mb-10 tw-border tw-border-gray-50 sm:tw-w-full md:tw-w-4/6 md:tw-max-w-lg tw-bg-white tw-shadow-xl tw-rounded-md"
    >
      <div class="tw-text-2xl tw-text-pink-700 tw-pb-1">Cadastro do totem</div>

      <div class="tw-p-1">
        <v-text-field
          v-model.trim="$v.include.nome.$model"
          label="Nome do totem"
          required
          depressed
          hide-details
        ></v-text-field>
        <div v-if="$v.include.nome.$dirty">
          <div class="error--text tw-text-sm" v-if="!$v.include.nome.required">
            Esse campo não pode ficar vazio
          </div>
          <div class="error--text" v-if="!$v.include.nome.minLength">
            O nome do totem deve conter pelo menos
            {{ $v.include.nome.$params.minLength.min }} letras.
          </div>
        </div>
      </div>
      <div class="tw-p-1">
        <v-text-field
          v-model="$v.include.descricao.$model"
          label="Descrição"
          depressed
          hide-details
          required
        ></v-text-field>
        <div v-if="$v.include.descricao.$dirty">
          <div
            class="error--text tw-text-sm"
            v-if="!$v.include.descricao.required"
          >
            Campo vazio
          </div>
          <div class="error--text" v-if="!$v.include.descricao.minLength">
            A descrição do totem deve conter pelo menos
            {{ $v.include.descricao.$params.minLength.min }} letras.
          </div>
        </div>
      </div>
      <div
        class="tw-mt-5 tw-py-2 tw-my-2 tw-text-gray-400 tw-font-normal tw-text-sm tw-uppercase tw-text-center"
      >
        Endereço
      </div>
      <div class="tw-py-1 tw-grid tw-grid-cols-8 tw-items-center">
        <div class="tw-col-span-8 sm:tw-col-span-6">
          <input
            v-model="$v.include.endereco.cep.$model"
            v-mask="['#####-###']"
            class="tw-border-2 tw-border-solid tw-p-3 tw-outline-none tw-w-full"
            :class="{
              'tw-border-gray-100': $v.include.endereco.cep.$invalid,
              'tw-border-blue-600': !$v.include.endereco.cep.$invalid,
            }"
            placeholder="Digite seu CEP"
          />
          <v-text-field
            v-if="false"
            v-model="$v.include.endereco.cep.$model"
            label="CEP"
            name="cep"
            depressed
            hide-details
            required
            outlined
            v-mask="['#####-###']"
            class="tw-border tw-border-solid"
            :class="{
              'tw-border-red-400': $v.include.endereco.cep.$invalid,
              'tw-border-gray-400': !$v.include.endereco.cep.$invalid,
            }"
          ></v-text-field>
        </div>
        <div class="tw-col-span-8 sm:tw-col-span-2 text-center sm:text-left">
          <button
            class="tw-mx-auto tw-mt-1 sm:tw-mt-0 sm:tw-mx-0 tw-p-3 tw-border-2 tw-border-solid"
            :class="{
              'tw-bg-gray-100 tw-text-black tw-border-gray-100':
                $v.include.endereco.cep.$invalid,
              'tw-bg-blue-600 tw-text-white tw-border-blue-600':
                !$v.include.endereco.cep.$invalid,
            }"
            :disabled="$v.include.endereco.cep.$invalid"
            @click="buscarCEP()"
          >
            Busca CEP
          </button>
        </div>
      </div>
      <div v-if="$v.include.endereco.cep.$dirty" class="tw-py-1">
        <div
          class="error--text tw-text-sm"
          v-if="!$v.include.endereco.cep.required"
        >
          Campo vazio
        </div>
        <div class="error--text" v-if="!$v.include.endereco.cep.minLength">
          CEP inválido
        </div>
      </div>

      <div class="tw-py-1">
        <v-text-field
          v-model.trim="$v.include.endereco.estado.$model"
          label="Estado"
          name="estado"
          depressed
          v-mask="['AA']"
          hide-details
          required
        ></v-text-field>
        <div v-if="$v.include.endereco.estado.$dirty" class="tw-py-1">
          <div
            class="error--text tw-text-sm"
            v-if="!$v.include.endereco.estado.required"
          >
            Campo vazio
          </div>
          <div class="error--text" v-if="!$v.include.endereco.estado.minLength">
            Estado inválido
          </div>
        </div>
      </div>
      <div class="tw-py-1">
        <v-text-field
          v-model="$v.include.endereco.cidade.$model"
          label="Cidade"
          name="cidade"
          depressed
          hide-details
          required
        ></v-text-field>
        <div v-if="$v.include.endereco.cidade.$dirty" class="tw-py-1">
          <div
            class="error--text tw-text-sm"
            v-if="!$v.include.endereco.cidade.required"
          >
            Campo vazio
          </div>
          <div class="error--text" v-if="!$v.include.endereco.cidade.minLength">
            Cidade inválida
          </div>
        </div>
      </div>

      <div class="tw-py-1">
        <v-text-field
          v-model="$v.include.endereco.bairro.$model"
          label="Bairro"
          name="Bairro"
          depressed
          hide-details
          required
        ></v-text-field>
        <div v-if="$v.include.endereco.bairro.$dirty" class="tw-py-1">
          <div
            class="error--text tw-text-sm"
            v-if="!$v.include.endereco.bairro.required"
          >
            Campo vazio
          </div>
          <div class="error--text" v-if="!$v.include.endereco.bairro.minLength">
            Bairro inválido
          </div>
        </div>
      </div>
      <div class="tw-py-1">
        <v-text-field
          v-model="$v.include.endereco.logradouro.$model"
          label="Logradouro"
          name="logradouro"
          depressed
          hide-details
          required
        ></v-text-field>
        <div v-if="$v.include.endereco.logradouro.$dirty" class="tw-py-1">
          <div
            class="error--text tw-text-sm"
            v-if="!$v.include.endereco.logradouro.required"
          >
            Campo vazio
          </div>
          <div
            class="error--text"
            v-if="!$v.include.endereco.logradouro.minLength"
          >
            Logradouro inválido
          </div>
        </div>
      </div>
      <div class="tw-py-3 tw-text-right">
        <v-btn
          @click="submitForm()"
          :disabled="$v.$invalid"
          class="tw-bg-pink-700 tw-p-4 tw-px-8 tw-shadow-md tw-text-white"
          >Cadastro</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { createTotem, updateTotem } from "@/service/firebase";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import serviceCEP from "@/service/cep";
import BASE from "@/constants/bases";
export default {
  data() {
    return {
      dialog: true,
      nextValid: false,
      include: {
        compra_atual: {},
        descricao: "",
        endereco: {
          cep: "",
          cidade: "",
          estado: "",
          logradouro: "",
          bairro: "",
        },
        contadores: {
          countNormal: 700,
          countNormalTotal: 0,
          countNormalRibbon: 700,
          countNormalRibbonTotal: 0,
          countPolaroid: 700,
          countPolaroidTotal: 0,
          countPolaroidRibbon: 700,
          countPolaroidRibbonTotal: 0,
        },
        config: {
          pagamento_cartao_credito: false,
          pagamento_cartao_debito: false,
          pagamento_pix: false,
          polaroid: true,
          normal: true,
        },
        franqueado_id: "1",
        keep_alive: null,
        nome: "",
        status: "OFF",
        user_uid: "",
        acesso_url: "",
      },
    };
  },
  mounted() {
    if (this.$store.getters["user/getRole"] !== "ADMIN") {
      this.$router.push("/dashboard/totem/lista-totem");
    }
  },
  validations: {
    include: {
      nome: {
        required,
        minLength: minLength(3),
      },
      descricao: {
        required,
        minLength: minLength(3),
      },
      endereco: {
        cep: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(9),
        },
        cidade: {
          required,
          minLength: minLength(3),
        },
        estado: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(9),
        },
        bairro: {
          required,
          minLength: minLength(3),
        },
        logradouro: {
          required,
          minLength: minLength(3),
        },
      },
    },
  },
  methods: {
    buscarCEP() {
      let toresp = this.include.endereco.cep;
      serviceCEP
        .getEndereco(toresp)
        .then((resp) => {
          console.log(resp);
          if (resp.data.erro) {
            this.$root.errorModal("CEP NÃO ENCONTRADO");
          } else {
            this.include.endereco.cidade = resp.data.localidade;
            this.include.endereco.estado = resp.data.uf;
            this.include.endereco.logradouro = resp.data.logradouro;
            this.include.endereco.bairro = resp.data.bairro;
          }
        })
        .catch((error) => {
          console.error(error);
          this.$root.errorModal("CEP NÃO ENCONTRADO");
        });
    },
    validacaoCampos() {
      this.$v.$touch();
    },
    cleanForm() {
      this.include.nome = "";
      this.include.descricao = "";
      this.include.endereco.cep = "";
      this.include.endereco.bairro = "";
      this.include.endereco.cidade = "";
      this.include.endereco.estado = "";
      this.include.endereco.logradouro = "";
    },
    submitForm() {
      let self = this;
      this.validacaoCampos();
      if (this.$v.$invalid) {
        this.$root.errorModal("verifique os campos necessarios");
        return false;
      }
      this.loading = true;
      let toRequest = this.include;
      createTotem(toRequest)
        .then((resp1) => {
          let baseURLProd = `${BASE.SITE}/init/${resp1.id}`; //TODO: AJUSTAR
          updateTotem(resp1.id, { id: resp1.id });
          updateTotem(resp1.id, { acesso_url: baseURLProd })
            .then((resp2) => {
              console.log(resp2);
              this.$root.sucessoModal("Cadastrado com sucesso!");
              this.cleanForm();
              this.loading = false;
              this.$router.push("/dashboard/totem/lista-totem");
            })
            .catch((err2) => {
              this.$root.errorModal("Erro ao tentar cadastrado");
              console.log("ERR_N2", err2);
              this.loading = false;
            });
        })
        .catch((err1) => {
          this.$root.errorModal("Erro ao tentar cadastrado");
          console.log("ERR_N1", err1);
          self.loading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
